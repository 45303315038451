.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .container-route{
  background-color: #161616;
  position: relative;
flex: 1;
  overflow-y: hidden;
} */

.circularProgress{
  color: #ffffff;
  position: fixed;
  top: 40%;
  left: 46%;
  z-index: 0;
}

.transition-group{
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.container-route{
  padding-left: 50px;
  padding-right: 50px;
}

.container-route .fade-appear, 
.container-route .fade-enter {
  opacity: 0;
  transform: translateY(100%);
}

.container-route .fade-appear-done, 
.container-route .fade-enter-done {
  transition: 0.5s ease-in;
  transform: translateY(0px);
  opacity: 1;
}

.fade-exit{
  transition: 0.5s ease-out;
  transform: translateY(0px);
  opacity: 1;
}

.fade-exit-active{
  opacity: 0;
  transform: translateY(100%);
}

